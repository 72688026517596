@import '../../../../styles/customMediaQueries.css';

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorWhite);
  /* Loading BG color */
  border-radius: 20px;
}

.container {
  composes: aspectRatioWrapper;
  padding: 40px 60px;
  flex: 100%;
  width: 100%;
  @media (--viewportSm){
    padding: 30px;
  }
}

.name {
  font-family: 'Montserrat';
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: var(--marketplaceColor);
}

.description {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: var(--marketplaceColor);
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 20px 0;
}

.link {
  color: var(--marketplaceSecondaryColor);
  font-family: 'Montserrat';
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.buttonsContainer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 20px;
  @media  (--viewportSm){
    flex-wrap: wrap;
  }
}

.button {
  composes: buttonDefault from global;
  max-width: 400px;

  &:hover {
    text-decoration: none;
  }
}

.messageButton {
  composes: buttonDefault from global;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 150px;
  letter-spacing: 0.04em;
  border: 3px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
  color: var(--marketplaceColor);
  @media (--viewportSm){
    width: 100%;
  }
  &:hover {
    color: var(--colorWhite);
    background-color: var(--marketplaceColorDark);
    border: 3px solid var(--marketplaceColorDark);

    &:disabled {
      background-color: var(--colorGrey100);
    }
  }

  &:focus {
    color: var(--colorWhite);
    background-color: var(--marketplaceColorDark);
    border: 3px solid var(--marketplaceColorDark);
  }

  &:disabled {
    border: none;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
  @media (--viewportSm){
      flex-wrap: wrap;
      row-gap: 0 !important;
  }
}

.info {
  composes: description;
  font-size: 16px;
}

.infoTitle {
  composes: info;
  font-weight: 600;
}

.bar {
  height: 30px;
  width: 1px;
  background-color: var(--marketplaceColor);
  margin: 0 8px;
}